import React from "react";
import { Content } from "patterns/Content";
import { ContentBlock } from "patterns/ContentBlock";
import { MastheadNewsletter } from "patterns/MastheadNewsletter";
import { Shell } from "patterns/Shell";

import "../../scss/main.scss";

function NewsletterIssue9({
    location: {
        pathname,
    },
}) {
    return (
        <Shell
            pageDescription="Launched side project, walking habit, tracking my finances and more"
            pageSocialImage=""
            pageTitle="Issue #9"
            pathname={pathname}
        >
            <MastheadNewsletter
                authorImage={{
                    alt: "",
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: "https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format"
                }}
                authorName="Rob Simpson"
                title="Issue #9"
            />
            <div className="container-large">
                <Content
                    content={[
                        {spans: [], text: "It’s been a while since my last newsletter, but I figured I’d give a quick update as we’re officially a month into the new year.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🗓 Looking back at January"
                    content={[
                        {spans: [], text: "👷 Work", type: "heading4"},
                        {spans: [], text: "For the most part, I’ve been working with an agency 3 days a week (since September), it’s all NextJS and Sanity work so far.", type: "paragraph"},
                        {spans: [], text: "One project was for a honey brand and the other is for an esports team – both of these projects have been exciting to work on.", type: "paragraph"},
                        {spans: [], text: "The only other notable project I’ve been working on is some design work for an NFT platform – design-wise it’s been really fun to work on.", type: "paragraph"},
                        {spans: [], text: "I’ve not taken on any new clients since around September last year, but I think I’ll look at branching out in March (once my second daughter is born and I’ve found my feet).", type: "paragraph"},
                        {spans: [], text: "⏳ 4 day work week", type: "heading4"},
                        {spans: [], text: "I’ve been rocking a 4-day work week since November, 4 days of client work that is (I use my Fridays to work on my own stuff).", type: "paragraph"},
                        {spans: [], text: "Now that I’ve experienced it, I wouldn’t want to consistently do a 5-day work week.", type: "paragraph"},
                        {spans: [], text: "🧑‍🍳 Launched Stryve", type: "heading4"},
                        {spans: [{
                            start: 56,
                            end: 62,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://www.stryve.life/",
                            },
                        }], text: "You may have seen, but I launched my new side project – Stryve. This is where I get to focus on my other interests outside of design and coding – that being cooking and health.", type: "paragraph"},
                        {spans: [], text: "It’s still pretty empty and I’ve got more features to add, but I’m really happy with it so far.", type: "paragraph"},
                        {spans: [], text: "My main focus was to make the cooking experience really smooth as this was such a pain point I had on other cooking websites.", type: "paragraph"},
                        {spans: [], text: "Having built a bunch of projects for clients using Prismic, it’s really nice to use it myself as a content editor.", type: "paragraph"},
                        {spans: [], text: "👟 Walking habit", type: "heading4"},
                        {spans: [], text: "As it was the new year, I wanted to develop some healthier habits again. I really enjoy walking, but I’ve never done it consistently.", type: "paragraph"},
                        {spans: [], text: "So I set myself a goal to go for a ~30-minute walk each day – which I actually managed.", type: "paragraph"},
                        {spans: [], text: "It never felt like a chore that I had to do because I did it so early in the day and I also got to listen to some new audiobooks.", type: "paragraph"},
                        {spans: [{
                            start: 117,
                            end: 151,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://www.stryve.life/blog/building-a-consistent-walking-habit",
                            },
                        }], text: "If you’re interested in learning more about how I made this habit stick, I wrote a blog post on Stryve detailing how I built a consistent walking habit.", type: "paragraph"},
                        {spans: [], text: "📙 Finished 2.5 books", type: "heading4"},
                        {spans: [], text: "Because of my walking, I managed to finish 1.5 books and then I read another (pretty short one).", type: "paragraph"},
                        {spans: [{
                            start: 36,
                            end: 62,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://www.amazon.co.uk/Proof-Plants-science-plant-based-planet/dp/1760890049/",
                            },
                        }], text: "The first book that I finished was “the proof is in the plants”, the science around a plant-based diet.", type: "paragraph"},
                        {spans: [{
                            start: 50,
                            end: 84,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://www.amazon.co.uk/Why-Nobody-Told-This-Before/dp/0241529719/",
                            },
                        }], text: "Once I had finished that, I started listening to “why has nobody told me this before” – which is all about mental health.", type: "paragraph"},
                        {spans: [{
                            start: 25,
                            end: 54,
                            type: "hyperlink",
                            data: {
                                link_type: "Web",
                                url: "https://www.amazon.co.uk/RHS-How-Grow-Plants-Seeds/dp/1784727628/1",
                            },
                        }], text: "Lastly, I read the book “how to grow plants from seeds” as we’re approaching the vegetable growing season again.", type: "paragraph"},
                        {spans: [], text: "💵 Tracking finances", type: "heading4"},
                        {spans: [], text: "This month I decided to take some action on tracking my finances. Until now I’ve not been overly frugal with my earnings, but I wanted to try and start the year off right (especially with the cost of living going up and another child on the way).", type: "paragraph"},
                        {spans: [], text: "So throughout January, I’ve tracked and categorised our spending for the month – there’s a lot of savings to be made.", type: "paragraph"},
                        {spans: [], text: "Off the back of this, I’ve set a budget for each category for February in the hope that we can save a lot more than we have in months prior.", type: "paragraph"},
                        {spans: [], text: "I also set up a bunch of pots in my Monzo account for all my sinking funds. These are expenses that I know will come up, so I can assign money to each and not have it as such a surprise.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 January goals"
                    checkList={[
                        {complete: true, text: "👟 Go for a walk every day"},
                        {complete: true, text: "📙 Finish 1 book"},
                        {complete: true, text: "✍️ Write a blog post on Stryve"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="🗓 Looking ahead to February"
                    content={[
                        {spans: [], text: "At some point in February, I’ll have around 2 weeks off from work for paternity leave.", type: "paragraph"},
                        {spans: [], text: "Other than that I probably won’t be doing any other work besides for the agency I’m working with and possibly odd bits for other existing clients.", type: "paragraph"},
                        {spans: [], text: "It’s going to be a month that will change my routine all over again, but we’ll see how it goes.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 February goals"
                    checkList={[
                        {complete: false, text: "👟 Continue my walking habit (even after having my second daughter)"},
                        {complete: false, text: "💵 Keep total monthly spend below £3k"},
                        {complete: false, text: "📙 Finish 2 books"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="💡 Freelance tip of the month"
                    quote="In the beginning, it makes sense to take on as much work as you can handle whilst you find your feet, once you’ve done that, scaling back and taking time for yourself is a great step for your long term health."
                />
                <ContentBlock
                    title="💰 Earnings for the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/b0f4ec85-03d5-4fae-8431-5b7c090207f1_jan-2022-income.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Income: £6.8k", type: "paragraph"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="📦 Product of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/b2d11765-018c-48b2-b3e8-874a614d182d_braincare-journal.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Brain care journal", type: "heading4"},
                        {spans: [], text: "I bought this brain care journal by Heights in November, there are some days where I skip an entry, but reflecting on the day and practising gratitude has been rather helpful.", type: "paragraph"},
                    ]}
                    buttonText="View product"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.yourheights.com/products/braincare-journal/",
                    }}
                />
                <ContentBlock
                    title="📙 Book of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/43b8d3f1-4daa-4410-b67f-b7a38938bc13_book-proof-in-the-plants.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "The proof is in the plants", type: "heading4"},
                        {spans: [], text: "If you’re interested in the science of why a plant-based diet is good for our long term health – I’d definitely recommend this book. Even adding a few more whole-food plant-based meals into your diet could make a big change.", type: "paragraph"},
                    ]}
                    buttonText="View book"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.amazon.co.uk/Proof-Plants-science-plant-based-planet/dp/1760890049/",
                    }}
                />
            </div>
        </Shell>
    );
}

export default NewsletterIssue9;
